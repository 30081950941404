
import { Component, Inject, Vue } from "vue-property-decorator";
import accountBannerImage from "@/assets/account-banner-bg.png";
import { IS_MOBILE_SYMBOL } from "@/constants";

@Component({})
export default class AccountBanner extends Vue {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  private accountBannerImage = accountBannerImage;
}
