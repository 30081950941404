export class AccountEntity {
  _id!: string;
  image?: string;
  userId?: string;
  price!: number;
  description?: string;
  link?: string;
  isForBox!: boolean;
  __typename = "Account" as const;

  constructor(opts: any) {
    Object.assign(this, opts);
  }
}
