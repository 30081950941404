
import { Component, Prop, Vue } from "vue-property-decorator";
import { AccountEntity } from "../../entities/account.entity";
import BaseList from "@/components/base/BaseList.vue";
import AccountCard from "@/components/accounts/AccountCard.vue";

@Component({
  components: {
    AccountCard,
    BaseList,
  },
})
export default class AccountCardList extends Vue {
  @Prop() value!: AccountEntity[] | null;

  colsMap = [
    [0, 1],
    [450, 2],
  ];
}
