
import { Component, Vue } from "vue-property-decorator";
import { AccountEntity } from "@/entities/account.entity";
import { AccountBoxEntity } from "@/entities/account-box.entity";
import gql from "graphql-tag";
import AccountCardList from "@/components/accounts/AccountCardList.vue";
import ScrollFetchMore from "@/components/base/ScrollFetchMore.vue";
import TopAppBar from "@/components/base/TopAppBar.vue";
import AccountBoxSlideList from "@/components/accounts/AccountBoxSlideList.vue";
import AccountBanner from "@/components/accounts/AccountBanner.vue";
import { useFindAll } from "@/graphql/use-find-all";
import { AccountBoxFragment, AccountShortFragment } from "@/graphql/fragments";
import { useScroll } from "@/graphql/use-scroll";

@Component({
  components: {
    TopAppBar,
    ScrollFetchMore,
    AccountBanner,
    AccountBoxSlideList,
    AccountCardList,
  },
  metaInfo: {
    title: "Покупка аккаунтов",
  },
  apollo: {
    boxes: {
      update(data) {
        return data.findAllAccountBox
          ? data.findAllAccountBox.map((item) => new AccountBoxEntity(item))
          : null;
      },
      fetchPolicy: "cache-and-network",
      query: useFindAll("AccountBox", AccountBoxFragment),
      variables: {
        options: {
          sort: "-accountIds price",
        },
      },
    },
    accounts: {
      update(data) {
        return data.scrollAccount
          ? data.scrollAccount.docs.map((item) => new AccountEntity(item))
          : null;
      },

      query: useScroll("Account", AccountShortFragment),
      variables() {
        return {
          filter: {
            isForBox: { eq: false },
          },
          options: {
            sort: "-price",
            limit: 10,
          },
        };
      },
    },
  },
})
export default class AccountMarket extends Vue {
  private boxes: AccountBoxEntity[] | null = null;
  private accounts: AccountEntity[] | null = null;
  private showTempDialog = true;

  onAccountBought(account: AccountEntity) {
    const accounts =
      this.$apollo.queries.accounts["observer"].lastResult.data[
        Object.keys(
          this.$apollo.queries.accounts["observer"].lastResult.data
        )[0]
      ].docs;

    accounts.splice(
      accounts.findIndex(({ _id }) => _id === account._id),
      1
    );

    this.accounts?.splice(
      this.accounts?.findIndex(({ _id }) => _id === account._id),
      1
    );
  }
}
