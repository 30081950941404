
import {
  Component,
  Inject,
  InjectReactive,
  Prop,
  Vue,
} from "vue-property-decorator";
import { AccountEntity } from "@/entities/account.entity";
import Price from "@/components/base/Price.vue";
import defaultAccountImage from "@/assets/account-zip.svg";
import GradientBtn from "@/components/base/GradientBtn.vue";
import gql from "graphql-tag";
import { IS_MOBILE_SYMBOL, USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";

@Component({
  components: {
    Price,
    GradientBtn,
  },
})
export default class AccountCard extends Vue {
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  defaultAccountImage = defaultAccountImage;
  dialog = false;
  loading = false;
  link: string | null = null;
  @Prop() value!: AccountEntity;

  close() {
    this.dialog = false;
    this.$emit("bought", this.value);
  }

  error(e: Error) {
    this.dialog = false;
    this.$notify({
      type: "info",
      text: "Данный аккаунт недоступен для покупки",
    });
    this.close();
  }

  async buy() {
    const data = await this.$apollo
      .mutate({
        mutation: gql`
          mutation ($_id: ID!) {
            buyAccount(_id: $_id) {
              link
            }
          }
        `,
        variables: {
          _id: this.value._id,
        },
      })
      .catch((e) => {
        this.error(e);
      });

    if (!data) {
      return;
    }

    const {
      data: {
        buyAccount: { link },
      },
    } = data;
    this.link = link;
    this.loading = false;
  }
}
