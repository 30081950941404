import gql from "graphql-tag";

export function useScroll(modelName: string, fragment: any) {
  const fragmentName = fragment.definitions[0].name.value;

  return gql`
    query($filter: MongooseFilterDto${modelName}, $options: MongooseScrollOptionsDto){
        scroll${modelName}(filter: $filter, options: $options){
        nextScrollToken
        docs{
            ...${fragmentName}
        }
     }
    }
    ${fragment}
  `;
}
